<template>
    <div>
        <van-nav-bar title="我要投诉" @click-left="leftReturn" left-arrow>
        </van-nav-bar>
        <div>
            <div class="title-part">
                <img src="../../../assets/images/head-quotation-mark.png">
                <span>我要投诉</span>
            </div>
            <!--            业主信息-->
            <div class="part">
                <div class="part-inputpart">
                    <div class="part-inputpart-row">
                        <div :class=" 0==name.trim().length ? 'content-none' : 'content-have'"></div>
                        <span class="part-inputpart-row-header">联系人</span>
                        <span class="content-divide">|</span>
                        <input v-model="name" placeholder="填写联系人">
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0==phone.trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">联系电话</span>
                        <span class="content-divide">|</span>
                        <input type="tel" maxlength="11" v-model="phone" placeholder="填写方便联系的电话" >
                    </div>
                    <div class="part-inputpart-textarea">
                        <div class="part-inputpart-row">
                            <span :class=" 0!=problemDescription.trim().length ? 'content-have' : 'content-none' "></span>
                            <span class="part-inputpart-row-header">问题描述</span>
                        </div>
                        <textarea v-model="problemDescription" placeholder="你遇到了什么问题"></textarea>
                    </div>
                </div>
            </div>
            <div class="part part-button" v-if="checkType" :class="0==name.trim().length || 0==phone.trim().length || 0==problemDescription.trim().length ? '' : 'part-button-enabled' " >提交中</div>
            <div class="part part-button" v-if="!checkType" :class="0==name.trim().length || 0==phone.trim().length || 0==problemDescription.trim().length ? '' : 'part-button-enabled' " @click="formSubmit">提交</div>
        </div>
    </div>
</template>


<script>

    import {
        NavBar, Toast
    } from 'vant'
    import {userAddComplaint} from "../../../getData/getData";
    import {getUserId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";



    export default {

        name: "IHaveComplaint",

        components: {
            [NavBar.name]: NavBar
        },

        data() {
            return {
                user_id:'',
                name: '',
                phone: '',
                problemDescription: '',
                personisnull:true,
                checkType:false
            }
        },

        created() {

        },
        default(){
        },
        methods: {
            //初始化数据
            userAddComplaint(){
                const that = this
                let getUserAddComplaintData = {
                    user_id:globaluserId(),
                    complaintDetail:that.problemDescription,
                    userName:that.name,
                    mobile:that.phone
                }
                userAddComplaint(getUserAddComplaintData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.checkType = false
                        if (response.data.code == 0){
                            responseUtil.alertMsg(that,"投诉成功")
                            that.name='';
                            that.phone='';
                            that.problemDescription=''
                            that.$router.go(-1)
                        }else if (response.data.code != 0) {
                            responseUtil.alertMsg(that,response.data.msg)
                        } else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },

            formSubmit:function(){
                let that = this;
                if(that.checkType){
                    return;
                }
                that.checkType =true
                //姓名校验
                if(that.name==''||that.name==undefined){
                    responseUtil.alertMsg(that,"姓名不能为空")
                    this.personisnull=false
                    that.checkType = false
                }else{
                    //姓名汉字正则校验
                    var mobileA = /^[\u2E80-\u9FFF]+$/;
                    let isMobileA =  mobileA.exec(that.name);
                    if(!isMobileA){
                        responseUtil.alertMsg(that,"请输入正确的姓名")
                        that.checkType = false
                    }else{
                        //联系校验
                        if(that.phone==''||that.phone===undefined){
                            responseUtil.alertMsg(that,"联系方法不能为空")
                            that.checkType = false
                        }else{
                            var mobile = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                            let isMobile =  mobile.exec(that.phone);
                            if(!isMobile){
                                responseUtil.alertMsg(that,"请输入正确的电话号码")
                                that.checkType = false
                            }else{
                                if(that.problemDescription==''||that.problemDescription==undefined){
                                    responseUtil.alertMsg(this,"请输入您要投诉的问题")
                                    that.checkType = false
                                }else{
                                    that.checkType = true
                                    that.userAddComplaint();//调用接口
                                }
                            }
                        }
                    }
                }
            },
            problem(event){
                console.log(event.data)
            }

        }


    }
</script>

<style scoped>

    .title-part{
        position: relative;
        margin-top: 24px;
    }

    .title-part img{
        width: 68px;
    }

    .title-part span{
        position: absolute;
        z-index: 10;
        font-weight: 900;
        font-size: 28px;
        top: 39px;
        left: 33px;
    }

    .part {
        margin: 15px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-button{
        height: 50px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 120px;
    }

    .part-button-enabled{
        background-image:linear-gradient(to right,#ffc274, #ff5d3b);
    }

    .part-inputpart div:last-child{
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 50px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-header{
        font-size: 14px;
        width: 80px;
        color: black;
        font-weight: 900;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .part-inputpart-row-normaltext{
        color: black;
    }

    .part-inputpart-row-housetype input{
        width: 18px;
        margin-left: 10px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin:0px 10px;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        border-radius: 8px;
        background-color: white;
    }

    .part-inputpart-textarea div:first-child {
        margin-top: 0;
    }

    .part-inputpart-textarea textarea {
        height: 50px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 15px;
        margin: 0px 15px 15px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }


</style>
